<template>
    <zw-sidebar @shown="shown"
                :title="this.payload.id ? $t('common.form.title.editAttribute')  + ' (' + this.payload.id + ')' : $t('common.form.title.createAttribute')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.name"
                                        name="name"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>

                    <b-col cols="12">
                        <zw-select-group v-model="options_group"
                                         :options="optionGroups"
                                         name="options_group"
                                         :label-prefix="labelPrefix"
                                         @change="getOptions"
                        />
                    </b-col>

                    <b-col cols="12">
                        <b-form-group :label="$t(labelPrefix+'options')">
                            <multiselect
                                v-model="form.options"
                                :options="groupOptions"
                                :multiple="true"
                            >
                            </multiselect>
                        </b-form-group>
                    </b-col>

                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Customer-Category-modal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                name: '',
                value: '',
                options: '',
            },
            optionGroups: [],
            options_group: null,
            groupOptions: [],
            form: {},
            labelPrefix: 'settings.attribute.label.',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getLocales', 'getDocuments']),
        ...mapGetters('Attributes', ['getAttribute', 'getAttributeOptionGroups']),
        getOptions() {
            this.groupOptions = []
            if (this.options_group) {
                const selectedGroup = this.getAttributeOptionGroups().find(group => {
                    return group.id == this.options_group
                })
                if (selectedGroup) {
                    this.groupOptions = selectedGroup.options.split(',').map(function (item) {
                        return item.trim()
                    });
                }
            }
        },
        shown() {
            const store = this.$store.dispatch('Attributes/fetchAttribute', this.payload.id)
            const optionGroups = this.$store.dispatch('Attributes/fetchAttributeOptionGroups')

            Promise.all([store, optionGroups])
                .then(() => {
                    this.form = {
                        ...this.defaultForm,
                        ...JSON.parse(JSON.stringify(this.getAttribute()))
                    }

                    this.form.options = this.form.options ? this.form.options.split(',').map(function (item) {
                        return item.trim()
                    }) : []

                    this.optionGroups = []
                    this.getAttributeOptionGroups().forEach(group => {
                        this.optionGroups.push({value: group.id, text: group.name})
                    })

                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true

                    let data = JSON.parse(JSON.stringify(this.form))
                    data.options = data.options.join(',')

                    this.$store.dispatch('Attributes/saveAttribute', data)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>